<template>
  <svg class="w-full h-auto" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.57 57.85">
    <!-- fill="#414042"
    fill="#6e6e82" -->
    <g id="Layer_1-2">
      <g>
        <g>
          <g>
            <path fill="currentColor" d="M36.59,21.55c0,2.64-.46,4.86-1.38,6.68-.92,1.82-2.17,3.29-3.73,4.39-1.57,1.11-3.4,1.9-5.49,2.39-2.09,.48-4.33,.72-6.72,.72h-5.27c-.5,0-.94,.17-1.32,.5-.38,.34-.56,.8-.56,1.38v11.61c0,.42-.16,.78-.47,1.1s-.68,.47-1.1,.47H1.57c-.42,0-.78-.16-1.1-.47s-.47-.68-.47-1.1V12.51c0-.88,.17-1.66,.5-2.35,.33-.69,.77-1.27,1.32-1.73,.54-.46,1.13-.81,1.76-1.04,.63-.23,1.26-.35,1.88-.35h13.81c2.38,0,4.63,.26,6.75,.78,2.11,.52,3.95,1.36,5.52,2.51,1.57,1.15,2.8,2.65,3.7,4.49,.9,1.84,1.35,4.08,1.35,6.72Zm-12.18-.06c0-1.76-.47-3.11-1.41-4.05-.94-.94-2.29-1.41-4.05-1.41H8.35c.33,0,.71,.07,1.13,.22,.42,.15,.81,.37,1.16,.66,.36,.29,.66,.66,.91,1.1,.25,.44,.38,.93,.38,1.48v5.4c0,.59,.2,1.06,.6,1.41,.4,.36,.85,.53,1.35,.53h5.08c1.72,0,3.05-.46,4.02-1.38,.96-.92,1.44-2.24,1.44-3.95Z" />
            <path fill="currentColor" d="M64.97,17.72c.38-.33,.82-.5,1.32-.5h4.48c.42,0,.8-.15,1.13-.44,.34-.29,.5-.67,.5-1.13v-3.2c0-.79-.16-1.53-.47-2.2-.31-.67-.73-1.24-1.26-1.73-.52-.48-1.11-.85-1.76-1.1-.65-.25-1.31-.38-1.98-.38h-25.5c-.46,0-.85,.16-1.16,.47-.31,.31-.47,.68-.47,1.1v7.03c0,.46,.16,.84,.47,1.13,.31,.29,.7,.44,1.16,.44h10.98v23.35h-10.98c-.46,0-.85,.15-1.16,.44-.31,.29-.47,.67-.47,1.13v7.03c0,.42,.16,.78,.47,1.1,.31,.31,.7,.47,1.16,.47h25.5c.67,0,1.33-.13,1.98-.38,.65-.25,1.23-.62,1.76-1.1,.52-.48,.94-1.06,1.26-1.73,.31-.67,.47-1.4,.47-2.2v-3.2c0-.46-.17-.84-.5-1.13-.33-.29-.71-.44-1.13-.44h-4.48c-.5,0-.94-.17-1.32-.5-.38-.34-.56-.8-.56-1.38V19.1c0-.59,.19-1.05,.56-1.38Z" />
          </g>
          <g>
            <path fill="currentColor" d="M173.13,6.96c.46,0,.85,.16,1.16,.47,.31,.31,.47,.68,.47,1.1v7.03c0,.46-.16,.84-.47,1.13-.31,.29-.7,.44-1.16,.44h-14.56c.92,0,1.75,.34,2.48,1,.73,.67,1.1,1.42,1.1,2.26v28.74c0,.46-.17,.82-.5,1.07-.29,.29-.67,.44-1.13,.44h-8.72c-.46,0-.84-.15-1.13-.44-.34-.25-.5-.61-.5-1.07V19.01c0-.59-.19-1.05-.56-1.38-.38-.33-.82-.5-1.32-.5h-4.48c-.42,0-.8-.15-1.13-.44-.34-.29-.5-.67-.5-1.13v-3.2c0-.79,.16-1.53,.47-2.2,.31-.67,.73-1.24,1.26-1.73,.52-.48,1.11-.85,1.76-1.1,.65-.25,1.31-.38,1.98-.38h25.5Z" />
            <path fill="currentColor" d="M170.95,50.52c-.25-.33-.38-.65-.38-.94s.02-.48,.06-.56l14.56-40.04c.46-1.21,1.3-1.82,2.51-1.82h9.73c1.21,0,2.05,.61,2.51,1.82l14.56,40.04c.04,.08,.06,.27,.06,.56,0,.34-.14,.64-.41,.91s-.58,.41-.91,.41h-8.1c-1.05,0-1.76-.46-2.13-1.38l-3.26-8.85c-1.51,.59-3.03,1.07-4.58,1.44-1.55,.38-3.04,.66-4.49,.85-1.44,.19-2.79,.31-4.05,.38-1.26,.06-2.34,.09-3.26,.09h-1.47c-.4,0-.7-.02-.91-.06,.75,.13,1.37,.54,1.85,1.26,.48,.71,.53,1.55,.16,2.51l-.88,2.38c-.38,.92-1.09,1.38-2.13,1.38h-8.1c-.29,0-.61-.13-.94-.38Zm15.94-16.07c1.17-.08,2.38-.23,3.64-.44,1.09-.17,2.23-.41,3.42-.72,1.19-.31,2.33-.76,3.42-1.35l-4.83-13.81-5.65,16.32Z" />
          </g>
        </g>
        <g>
          <circle fill="#6e6e82" cx="107.29" cy="28.92" r="11.36" />
          <path fill="#6e6e82" d="M100.52,45.73l-.13-.05c-4.47-1.84-7.96-5.31-9.82-9.77l3.79-1.58c1.43,3.43,4.11,6.1,7.53,7.53l.13,.05-1.49,3.82Zm13.75-.08l-1.58-3.79c3.45-1.44,6.14-4.14,7.57-7.6l3.82,1.5-.02,.06c-1.85,4.48-5.32,7.97-9.79,9.84Zm-19.98-21.97l-1.53-.72-2.25-.87,.03-.06h0c1.84-4.46,5.3-7.95,9.76-9.81l1.59,3.79c-3.44,1.44-6.12,4.14-7.54,7.59l-.04,.09Zm25.93-.18c-1.44-3.44-4.14-6.11-7.59-7.54l1.52-3.81,.06,.02c4.44,1.83,7.92,5.29,9.79,9.73l-3.79,1.59Z" />
          <path fill="#6e6e82" d="M97.88,51.95l-.06-.03h0c-6.13-2.52-10.92-7.28-13.48-13.4l3.79-1.58c2.14,5.11,6.13,9.08,11.25,11.19l.2,.1-1.7,3.73Zm18.98-.07l-1.58-3.79c5.12-2.13,9.1-6.13,11.21-11.26l.03-.07,3.8,1.56-.03,.07c-2.53,6.14-7.3,10.93-13.42,13.48Zm-28.77-30.86l-3.82-1.51,.03-.07c2.52-6.11,7.27-10.89,13.38-13.45l1.59,3.79c-5.1,2.14-9.07,6.13-11.18,11.25Zm38.35-.13c-2.14-5.09-6.13-9.06-11.24-11.16h0s-.07-.03-.07-.03l1.59-3.78h.01c6.14,2.53,10.92,7.28,13.49,13.38l-3.79,1.59Z" />
          <path fill="#6e6e82" d="M95.48,57.85l-.09-.04h0c-7.71-3.17-13.72-9.15-16.93-16.85l3.79-1.58c2.79,6.68,8.01,11.88,14.71,14.64l.27,.14-1.74,3.7Zm23.83-.09l-1.58-3.79c6.69-2.79,11.89-8.01,14.65-14.71l3.82,1.5-.04,.09c-3.17,7.69-9.16,13.7-16.86,16.91ZM82.18,18.61l-3.82-1.51,.03-.08c3.17-7.7,9.15-13.71,16.84-16.93l1.58,3.79c-6.67,2.79-11.86,8-14.61,14.68l-.02,.05Zm50.14-.17c-2.79-6.67-8.01-11.86-14.7-14.61l1.49-3.83s.08,.03,.11,.05c7.66,3.15,13.67,9.13,16.88,16.8l-3.79,1.59Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

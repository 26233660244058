<template>
  <svg class="w-full h-auto" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.57 55.07">
    <g id="Layer_1-2">
      <g>
        <g>
          <g>
            <path fill="currentColor" d="M36.59,20.16c0,2.64-.46,4.86-1.38,6.68-.92,1.82-2.17,3.29-3.73,4.39-1.57,1.11-3.4,1.9-5.49,2.39-2.09,.48-4.33,.72-6.72,.72h-5.27c-.5,0-.94,.17-1.32,.5-.38,.34-.56,.8-.56,1.38v11.61c0,.42-.16,.78-.47,1.1s-.68,.47-1.1,.47H1.57c-.42,0-.78-.16-1.1-.47s-.47-.68-.47-1.1V11.12c0-.88,.17-1.66,.5-2.35,.33-.69,.77-1.27,1.32-1.73,.54-.46,1.13-.81,1.76-1.04,.63-.23,1.26-.35,1.88-.35h13.81c2.38,0,4.63,.26,6.75,.78,2.11,.52,3.95,1.36,5.52,2.51,1.57,1.15,2.8,2.65,3.7,4.49,.9,1.84,1.35,4.08,1.35,6.72Zm-12.18-.06c0-1.76-.47-3.11-1.41-4.05-.94-.94-2.29-1.41-4.05-1.41H8.35c.33,0,.71,.07,1.13,.22,.42,.15,.81,.37,1.16,.66,.36,.29,.66,.66,.91,1.1,.25,.44,.38,.93,.38,1.48v5.4c0,.59,.2,1.06,.6,1.41,.4,.36,.85,.53,1.35,.53h5.08c1.72,0,3.05-.46,4.02-1.38,.96-.92,1.44-2.24,1.44-3.95Z" />
            <path fill="currentColor" d="M64.97,16.33c.38-.33,.82-.5,1.32-.5h4.48c.42,0,.8-.15,1.13-.44,.34-.29,.5-.67,.5-1.13v-3.2c0-.79-.16-1.53-.47-2.2-.31-.67-.73-1.24-1.26-1.73-.52-.48-1.11-.85-1.76-1.1-.65-.25-1.31-.38-1.98-.38h-25.5c-.46,0-.85,.16-1.16,.47-.31,.31-.47,.68-.47,1.1v7.03c0,.46,.16,.84,.47,1.13,.31,.29,.7,.44,1.16,.44h10.98v23.35h-10.98c-.46,0-.85,.15-1.16,.44-.31,.29-.47,.67-.47,1.13v7.03c0,.42,.16,.78,.47,1.1,.31,.31,.7,.47,1.16,.47h25.5c.67,0,1.33-.13,1.98-.38,.65-.25,1.23-.62,1.76-1.1,.52-.48,.94-1.06,1.26-1.73,.31-.67,.47-1.4,.47-2.2v-3.2c0-.46-.17-.84-.5-1.13-.33-.29-.71-.44-1.13-.44h-4.48c-.5,0-.94-.17-1.32-.5-.38-.34-.56-.8-.56-1.38V17.71c0-.59,.19-1.05,.56-1.38Z" />
          </g>
          <g>
            <path fill="currentColor" d="M173.13,5.57c.46,0,.85,.16,1.16,.47,.31,.31,.47,.68,.47,1.1v7.03c0,.46-.16,.84-.47,1.13-.31,.29-.7,.44-1.16,.44h-14.56c.92,0,1.75,.34,2.48,1,.73,.67,1.1,1.42,1.1,2.26v28.74c0,.46-.17,.82-.5,1.07-.29,.29-.67,.44-1.13,.44h-8.72c-.46,0-.84-.15-1.13-.44-.34-.25-.5-.61-.5-1.07V17.62c0-.59-.19-1.05-.56-1.38-.38-.33-.82-.5-1.32-.5h-4.48c-.42,0-.8-.15-1.13-.44-.34-.29-.5-.67-.5-1.13v-3.2c0-.79,.16-1.53,.47-2.2,.31-.67,.73-1.24,1.26-1.73,.52-.48,1.11-.85,1.76-1.1,.65-.25,1.31-.38,1.98-.38h25.5Z" />
            <path fill="currentColor" d="M170.95,49.13c-.25-.33-.38-.65-.38-.94s.02-.48,.06-.56l14.56-40.04c.46-1.21,1.3-1.82,2.51-1.82h9.73c1.21,0,2.05,.61,2.51,1.82l14.56,40.04c.04,.08,.06,.27,.06,.56,0,.34-.14,.64-.41,.91s-.58,.41-.91,.41h-8.1c-1.05,0-1.76-.46-2.13-1.38l-3.26-8.85c-1.51,.59-3.03,1.07-4.58,1.44-1.55,.38-3.04,.66-4.49,.85-1.44,.19-2.79,.31-4.05,.38s-2.34,.09-3.26,.09h-1.47c-.4,0-.7-.02-.91-.06,.75,.13,1.37,.54,1.85,1.26,.48,.71,.53,1.55,.16,2.51l-.88,2.38c-.38,.92-1.09,1.38-2.13,1.38h-8.1c-.29,0-.61-.13-.94-.38Zm15.94-16.07c1.17-.08,2.38-.23,3.64-.44,1.09-.17,2.23-.41,3.42-.72,1.19-.31,2.33-.76,3.42-1.35l-4.83-13.81-5.65,16.32Z" />
          </g>
        </g>
        <g>
          <path fill="#aafa33" d="M134.82,0c-26.83,2.12-27.05,14.09-25.32,20.22,.29,1.01,1.52,1.39,2.32,.72l7.42-6.23c.58-.49,1.36,.29,.87,.87l-6.23,7.42c-.68,.81-.3,2.04,.72,2.32C120.73,27.05,132.7,26.83,134.82,0Z" />
          <path fill="#aafa33" d="M79.75,55.07c26.83-2.12,27.05-14.09,25.32-20.22-.29-1.01-1.52-1.39-2.32-.72l-7.42,6.23c-.58,.49-1.36-.29-.87-.87l6.23-7.42c.68-.81,.3-2.04-.72-2.32-6.13-1.73-18.1-1.5-20.22,25.32Z" />
          <path fill="#aafa33" d="M134.82,55.07c-2.12-26.83-14.09-27.05-20.22-25.32-1.01,.29-1.39,1.52-.72,2.32l6.23,7.42c.49,.58-.29,1.36-.87,.87l-7.42-6.23c-.81-.68-2.04-.3-2.32,.72-1.73,6.13-1.5,18.1,25.32,20.22Z" />
          <path fill="#aafa33" d="M79.75,0c2.12,26.83,14.09,27.05,20.22,25.32,1.01-.29,1.39-1.52,.72-2.32l-6.23-7.42c-.49-.58,.29-1.36,.87-.87l7.42,6.23c.81,.68,2.04,.3,2.32-.72,1.73-6.13,1.5-18.1-25.32-20.22Z" />
        </g>
      </g>
    </g>
  </svg>
</template>
